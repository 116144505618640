<template>
  <div class="prime-view-container prime-left-menu full-height">
    <div class="prime-left-menu-container desktop" v-if="currentPatient">
      <div class="prime-left-menu-header">
        <h5>Patient Overview:</h5>
        <h5 class="prime-left-menu-name">
          {{
            currentPatient.payload.account.firstName + " " + currentPatient.payload.account.lastName
          }}
        </h5>
      </div>
      <div
        @click="selectMenuItem('general-info')"
        :class="{ 'active-item': activeListItem === 'general-info' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Patient Dashboard
        </div>
      </div>
      <div
        @click="selectMenuItem('conversations')"
        :class="{ 'active-item': activeListItem === 'conversations' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Conversations
        </div>
      </div>
      <div
        @click="selectMenuItem('tasks')"
        :class="{ 'active-item': activeListItem === 'tasks' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Tasks
        </div>
      </div>

      <div
        @click="selectMenuItem('event-notifications')"
        v-if="$can(I.VIEW_EVENT_NOTIFICATIONS)"
        :class="{ 'active-item': activeListItem === 'event-notifications' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Event Notifications
        </div>
      </div>

      <div
        @click="selectMenuItem('diagnosis-considerations')"
        v-if="$can(I.VIEW_DX_INSIGHTS)"
        :class="{ 'active-item': activeListItem === 'diagnosis-considerations' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Diagnosis Considerations
        </div>
      </div>
      <div
        v-else
        :class="{ 'active-item': activeListItem === 'diagnosis-considerations' }"
        class="disabled prime-left-menu-item"
        v-tooltip.top-start="{
          content: $orgMissingPermissions('The Diagnosis Considerations feature')
        }"
      >
        <div class="prime-left-menu-title">
          Diagnosis Considerations
        </div>
      </div>
      <div
        @click="selectMenuItem('quality-considerations')"
        v-if="$can(I.VIEW_GAPS_IN_CARE)"
        :class="{ 'active-item': $route.path.includes('quality-considerations') }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">Quality Considerations</div>
      </div>
      <div
        v-else
        :class="{ 'active-item': activeListItem === 'quality-considerations' }"
        class="prime-left-menu-item disabled"
      >
        <div class="prime-left-menu-title">Quality Considerations</div>
      </div>

      <div
        @click="selectMenuItem('medical-history')"
        v-if="$can(I.VIEW_MEDICAL_HISTORY)"
        :class="{ 'active-item': $route.path.includes('medical-history') }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">Medical History</div>
      </div>

      <div
        @click="selectMenuItem('programs')"
        :class="{ 'active-item': activeListItem === 'programs' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Programs
        </div>
      </div>
      <div
        @click="selectMenuItem('care-team')"
        :class="{ 'active-item': activeListItem === 'care-team' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Care Team
        </div>
      </div>
      <div
        @click="selectMenuItem('comm-prefs')"
        :class="{ 'active-item': activeListItem === 'comm-prefs' }"
        class="prime-left-menu-item"
      >
        <div class="prime-left-menu-title">
          Settings
        </div>
      </div>
    </div>
    <PrimePage titleAlignment="left">
      <div v-if="currentPatient" class="pad-lr-twenty full-height wrapper">
        <ion-row>
          <ion-col class="fixed-top">
            <PatientBanner :currentPatient="currentPatient" />
          </ion-col>
        </ion-row>
        <div class="scrollable-content">
          <router-view :currentPatient="currentPatient"></router-view>
        </div>
      </div>
    </PrimePage>
  </div>
</template>

<script>
import PrimePage from "@/components/Global/PrimePage";
import PatientBanner from "@/components/Settings/Patient/PatientBanner";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import { addIcons } from "ionicons";
import {
  add,
  arrowDown,
  arrowUp,
  calendar,
  call,
  chatboxes,
  remove,
  text,
  warning
} from "ionicons/icons";

addIcons({
  "ios-add": add.ios,
  "md-add": add.md,
  "md-chatboxes": chatboxes.md,
  "ios-chatboxes": chatboxes.ios,
  "ios-arrow-up": arrowUp.ios,
  "ios-arrow-down": arrowDown.ios,
  "md-remove": remove.md,
  "md-arrow-up": arrowUp.md,
  "md-call": call.md,
  "md-text": text.md,
  "ios-warning": warning.ios,
  "md-calendar": calendar.md
});

export default {
  name: "SettingsMain",
  components: {
    PrimePage,
    PatientBanner
  },
  data() {
    return {
      currentPatient: undefined,
      isPatientLoading: false,
      activeListItem: "general-info"
    };
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    }
  },
  created() {
    this.$route.params.id
      ? this.getCurrentPatient(this.$route.params.id)
      : khanSolo.log("No patient ID specified");
    this.changeMenuHighlight(this.$route.matched[2].name);

    EventBus.$on("patientPCPchanged", () => {
      this.getCurrentPatient(this.$route.params.id);
    });
  },
  updated() {
    this.changeMenuHighlight(this.getPath());
  },
  methods: {
    getPath() {
      let path = this.$route.path;
      path = path.replace(this.$route.params.id, "");
      path = path.replace(this.$route.matched[1].path, "");
      path = path.replace(/\//g, "");
      return path;
    },
    getCurrentPatient: async function(id) {
      if (id == undefined) {
        id = this.$route.params.id;
      }
      this.isPatientLoading = true;
      const method = "get";
      const path = document.config.patientSettings + id;
      //const path = document.config.patientSettings;

      httpSend({ path, method, authToken: true })
        .then(result => {
          this.currentPatient = result.data;
          this.isPatientLoading = false;
          this.$store.commit("chatProfessional/setCurrentPatient", this.currentPatient.payload);
        })
        .catch(error => {
          khanSolo.log(error);
          this.isPatientLoading = false;
        });
    },
    selectMenuItem: function(route) {
      this.$router.push({
        path: "/patient-settings/" + route + "/" + this.$route.params.id
      });
      this.changeMenuHighlight(route);
    },
    changeMenuHighlight: function(route) {
      this.activeListItem = route;
    }
  }
};
</script>

<style scoped>
.prime-left-menu-container {
  position: fixed;
  width: 300px;
  left: 0;
  height: 100%;
  background: white;
  padding-top: 60px;
}

.prime-left-menu-container .prime-left-menu-header {
  margin: 0px 20px 60px;
}

.prime-left-menu-container .prime-left-menu-header h5 {
  margin: 0 0 5px;
}

.prime-left-menu-container .prime-left-menu-header h5.prime-left-menu-name {
  color: var(--ion-color-primary);
  margin-bottom: 30px;
}

.prime-left-menu-container::before {
  content: "";
  position: absolute;
  background: var(--ion-color-light);
  height: 2px;
  top: 0;
  left: 20px;
  right: 20px;
}

.prime-left-menu-item {
  padding: 15px 20px 15px 40px;
  position: relative;
  font-weight: 400;
  cursor: pointer;
}

.prime-left-menu-item.active-item:after {
  display: none;
}

.prime-left-menu-item.active-item {
  background-color: var(--color-secondary-button);
  border-right: 2px solid var(--ion-color-primary);
}

.prime-left-menu-title {
  font-size: 14px;
  font-weight: 700;
}

ion-label {
  overflow: unset !important;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative;
  /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.disabled {
  color: gray;
  cursor: not-allowed;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.fixed-top {
  position: sticky;
  top: 0;
  z-index: 100;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
}
</style>
